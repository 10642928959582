
  import { PropType } from 'vue';
  import { Component, PropSync, Vue } from 'vue-property-decorator';

  import { Supplier } from '@/modules/entities/types/entities';
  import { Permissions } from '@/core/types/Permissions';
  import { namespace } from 'vuex-class';
  import { appMessageStore } from '@/core/store/app-messages.store';
  import { AppMessageType } from '@/core/components/app-message/entities';

  @Component
  export default class SupplierPaymentsComponent extends Vue {
    @PropSync('supplier', { type: Object as PropType<Supplier>, required: true }) supplierSync!: Supplier;
    @PropSync('editPaymentDueDays', { type: Boolean, default: false }) editPaymentDueDaysSync!: boolean;
    @PropSync('editDiscountedPaymentWithin', { type: Boolean, default: false })
    editDiscountedPaymentWithinSync!: boolean;
    @PropSync('editDiscountedPaymentPercentage', { type: Boolean, default: false })
    editDiscountedPaymentPercentageSync!: boolean;

    editPayment(type: string, value: boolean) {
      switch (type) {
        case 'editPaymentDueDaysSync':
          if (!Vue.prototype.$can(Permissions.editPreferredPaymentTerm)) {
            appMessageStore.actions.set({
              message: 'dictionary.insufficientPermissions',
              ttl: 5000,
              dismissed: false,
              type: AppMessageType.ERROR,
            });
            return;
          }
          this.editPaymentDueDaysSync = value;
          break;
        case 'editDiscountedPaymentWithinSync':
          if (!Vue.prototype.$can(Permissions.editPreferredPaymentDiscountTerm)) {
            appMessageStore.actions.set({
              message: 'dictionary.insufficientPermissions',
              ttl: 5000,
              dismissed: false,
              type: AppMessageType.ERROR,
            });
            return;
          }
          this.editDiscountedPaymentWithinSync = value;
          break;
        case 'editDiscountedPaymentPercentageSync':
          if (!Vue.prototype.$can(Permissions.editPreferredPaymentDiscountPercentage)) {
            appMessageStore.actions.set({
              message: 'dictionary.insufficientPermissions',
              ttl: 5000,
              dismissed: false,
              type: AppMessageType.ERROR,
            });
            return;
          }
          this.editDiscountedPaymentPercentageSync = value;
          break;
      }
    }
  }
